import React from "react";
import DownloadLink from "../common/DownloadLink";
import "./style.css";
import PortableText from "react-portable-text";

export default function PromiseList({ data }) {
  return (
    <div className="max-w-[85%] mx-auto px-[10px] py-5">
      <div className="flex flex-wrap" id="promise-list">
        {data?.promiseList?.map((item, index) => {
          return (
            <div
              className="flex w-full flex-col sm:flex-row justify-between show"
              key={index}
            >
              <div className="flex w-full justify-center mb-5 ps-svg">
                <div dangerouslySetInnerHTML={{ __html: item.svg }} />
              </div>
              <div className="w-full mb-[35px]">
                <PortableText
                  content={item._rawContent}
                  className="text-base text-[#323a44] font-semibold leading-[26px] max-w-[466px] block-content"
                />
              </div>
            </div>
          );
        })}
      </div>
      <br />
      <DownloadLink text={data?.linkTxt} link={data?.link} />
    </div>
  );
}
